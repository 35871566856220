<template>
  <v-container fluid>
    <v-col
      v-if="loading"
      cols="12">
      <div class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>
    </v-col>
    <v-card
      v-else>
      <v-card-title>
        <router-link :to="`/users/companies/${selectedCompany}/new`">
          <v-btn color="success">
            <v-icon left>add</v-icon>
            Neuer Mitarbeiter
          </v-btn>
        </router-link>
        <v-spacer/>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Mitarbeiter suchen"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="availableUsers"
        :search="search"
        class="elevation-1"
      >
        <template
          slot="item"
          slot-scope="props">
          <tr
            class="-clickable"
            @click="openUserDetails(props.item.id)">
            <td>{{ props.item.name }}</td>
            <td>
              <div
                class="d-inline-block mr-2 mb-2"
                v-for="(right, index) in props.item.rights"
                :key="index">
                <v-chip
                  v-if="right.selected"
                >
                  {{ right.label }}
                </v-chip>
              </div>
            </td>
            <td>
              <router-link :to="'/users/' + props.item.id">
                <v-btn
                  color="primary"
                  small>
                  <v-icon>search</v-icon>
                </v-btn>
              </router-link>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex'

export default {
  data() {
    return {
      search: '',
      selectedCompany: null,
      headers: [
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Rechte',
          sortable: false,
        },
        {
          text: 'Aktionen',
          value: 'name',
        },
      ],
    }
  },
  created() {
    if (this.$route.params.companyId) {
      this.selectedCompany = Number(this.$route.params.companyId)
    }

    this.fetchUsers(this.selectedCompany)
  },
  computed: {
    ...mapGetters({
      users: 'user/users',
      loading: 'user/loading',
    }),
    availableUsers() {
      if (!this.selectedCompany) {
        return this.users
      }

      return this.users.filter((user) => user.company_id === this.selectedCompany)
    },
  },
  methods: {
    ...mapActions('user', ['fetchUsers']),
    openUserDetails(userId) {
      this.$router.push({
        name: 'employee',
        params: {
          companyId: this.selectedCompany,
          userId,
        },
      })
    },
  },
}
</script>
